import './App.css';
import React, { useState } from 'react'
import { Modal } from 'antd';
import bannerUrl from './static/banner.png'
import logo from './static/logo.png'
import heart from './static/heart.png'
import project from './static/project.png'
import projectSpecial from './static/project-special.png'
import projectOne from './static/project-one.png'
import card1 from './static/card1.png'
import card2 from './static/card2.png'
import card3 from './static/card3.png'
import card4 from './static/card4.png'
import card5 from './static/card5.png'
import card6 from './static/card6.png'

import sample1 from './static/sample-image1.png'
import sample2 from './static/sample-image2.png'

import video_bg from './static/video-bg.png'

import video from './static/video.mp4'

import logoimg from './static/logo-img.png'
import qrcode from './static/qrcode.png'

import model from './static/model.png'

import phone from './static/phone_icon.png'
import address from './static/address_icon.png'


const Card = ({ title, description, image }) => {

  return (
    <div
      className="card"
    >
      <img className="card-img" src={image} alt=""/>
      <div className="card-title-wrap">{title}</div>
      <div className="card-description">{description}</div>
    </div>
  );
};

const CardDisplay = () => {
  const cards = [
    {
      title: "医疗前线",
      description: "在社区卫生中心，为患者提供个性化的健康管理方案。",
      image: card1,
    },
    {
      title: "企业健康",
      description: "为企业量身定制员工健康计划，提升团队活力和工作效率。",
      image: card2,

    },
    {
      title: "健康教育",
      description: "在教育和社区领取，普及健康知识，培养健康习惯",
      image: card3,
    },
    {
      title: "康复指导",
      description: "在运动中心，养生中心，帮助客户实现健康目标，提升生活质量",
      image: card4,
    },
    {
      title: "营养专家",
      description: "提供专业的营养咨询，助力客户达到最佳健康状态。",
      image: card5,
    },
    {
      title: "保险科技",
      description: "实现健康服务+保险的结合，充当客户和平台的桥梁，承担‘健康导航人’的重担。",
      image: card6,
    },
  ];

  return (
    <div className="card-container">
      {cards.map((card, index) => (
        <Card
          key={index}
          title={card.title}
          description={card.description}
          image={card.image}
        />
      ))}
    </div>
  );
};
function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="container">
      <div className="banner-wrap">
        <div className="banner">
          <img src={bannerUrl} className="banner-img" alt="" />
        </div>
        <div className="banner-content">
          <img src={logoimg} className="banner-title" alt="" />
          <img src={logo} className="banner-logo" alt="" />
          <div className="banner-text">
            加入广慈健康管理师项目，开启您的健康管理职业生涯，为个人和社会的健康贡献力量。
          </div>
          <div className="banner-text">
            获取您的专业认证，成为健康管理领域的专家！
          </div>
          <div className="banner-button" onClick={showModal}>
            立即报名
          </div>
          <img src={heart} className="banner-heart" alt="" />
          <div className="banner-shadow"></div>
        </div>
      </div>
      <div className="projDesc-wrap">
        <div className="projDesc-title">
          项目介绍
        </div>
        <div className="projDesc-description">
          <img src={project} alt="" className="projDesc-img" />
          <div className="projDesc-description-text">

          </div>
          <div className="projDesc-text">
            广慈健康管理师项目，是由瑞金医院权威背书，提供专业的培训认证的健康管理师项目。旨在培养具备全面健康管理知识和技能的专业人才，以满足日益增长的健康服务需求。
          </div>
          <div className="text-top-line">

          </div>
          <div className="text-left-line">

          </div>
          <div className="text-bottom-line">

          </div>

          <div className="rightView-top-line">

          </div>
          <div className="rightView-right-line">

          </div>
          <div className="rightView-bottom-line">

          </div>
        </div>
      </div>
      <div className="highlights-wrap">
        <div className="highlights">
          <div className="highlights-title">
            <div className="highlights-image">
              <img src={projectSpecial} className="highlight-img" alt="" />
              <img src={projectOne} className="highlight-img" alt="" />
            </div>
            <div className="highlights-image">
              <div className="highlight-img fontName">
                <div className="highlight-img-text" >
                  项目特色
                </div>
              </div>
              <div className="highlight-img">
                <div className="highlight-img-text">
                  独家定制
                </div>
              </div>
            </div>
          </div>
          <div className="highlights-container">
            <div className="highlights-container-item">
              <div className="highlight-item">
                <div className="highlight-item-title">
                  <div className="highlight-item-text">
                    权威认证
                  </div>
                  <div className="highlight-item-back"></div>
                </div>
                <div className="highlight-item-content">
                  瑞金医院权威认证，颁发《培训证明》，确保培训质量和认证的含金量。
                </div>
              </div>
              <div className="highlight-item">
                <div className="highlight-item-title">
                  <div className="highlight-item-text">
                    专业授课
                  </div>
                  <div className="highlight-item-back item2"></div>
                </div>
                <div className="highlight-item-content">
                  由涵盖心 血管科、消化科、血液科、 神经内科等常见病科室的专业医师授课，确保学员能够获得最前沿的健康知识和实践技能。
                </div>
              </div>
            </div>
            <div className="highlights-container-line"></div>
            <div className="highlights-container-item">
              <div className="highlight-item">
                <div className="highlight-item-title">
                  <div className="highlight-item-text">
                    课程体系
                  </div>
                  <div className="highlight-item-back item3"></div>
                </div>
                <div className="highlight-item-content">
                  精心规划的课程体系，涵盖常见慢性病管理、运动课程、家庭急救处理等关键领域，满足机构和个人的特殊需求。
                </div>
              </div>
              <div className="highlight-item">
                <div className="highlight-item-title">
                  <div className="highlight-item-text">
                    灵活学习
                  </div>
                  <div className="highlight-item-back item4"></div>
                </div>
                <div className="highlight-item-content">
                  线上线下两种培训方式，根据需求与时间安排，灵活选择。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="application-wrap">
        <div className="application-title">
          健康管理师市场与应用方向
        </div>
        <div className="application-tip">
          根据《健康中国2030规划纲要》“加快转变健康领域发展方式，全方位、全周期维护和保障人民健康“的要求，以及当前亚健康状态成为常态，人们对于健康服务的需求日益旺盛的市场环境下。健康管理市场严重供不应求，有资质认证的健康管理师，正成为炙手可热的职业。
        </div>
        <CardDisplay />
      </div>
      <div className="sample-wrap">
        <div className="sample">
          <div className="sample-title">
            证书样本
          </div>
          <div className="sample-image-wrap">
            <div className="sample-image" style={{ marginRight: '100px' }}>
              <img src={sample1} alt="" className="sample-img" />
              <div className="sample-img-text">
                线上培训结业证书
              </div>
            </div>
            <div className="sample-image">
              <img src={sample2} alt="" className="sample-img" />
              <div className="sample-img-text">
                线下培训结业证书
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="video-wrap">
        <div className="video">
          <img src={video_bg} className="video-img" alt="" />
        </div>
        <div className="video-title">
          课程试播
        </div>
        <video width="986" height="554" controls>
          <source src={video} type="video/mp4" />
          你的浏览器不支持 HTML5 视频。
        </video>
      </div>
      <div className="footer-wrap">
        <img src={logoimg} className="footer-img" alt="" />
        <div className="footer-content">
          <div className="content-info">
            <div className="content-info-wrapper">
              <img src={address} className="footer-phone" alt="" />
              地址：上海市浦东新区秦家港路1500号10幢130室

            </div>
          </div>
          <div className="content-info">
            <img src={phone} className="footer-phone" alt="" />
            联系电话：152 2132 3600

          </div>
        </div>
        <div className="footer-qrcode">
          <img src={qrcode} className="footer-qrcode-img" alt="" />
          <div className="footer-img-tip">微信扫码咨询</div>
        </div>
      </div>
      <div className="footer-code" onClick={() => window.open('https://beian.miit.gov.cn/', '_blank')}>
        沪ICP备2024102261号-1
      </div>
      <Modal closable={false} centered width={420} styles={{
        content: {
          padding: '0', boxShadow: '0px 20px 20px 0px rgba(6,25,46,0.2), 0px 2px 10px 0px rgba(188,188,188,0.5)', borderRadius: '10px'
        }
      }} open={isModalOpen} footer={null} onCancel={handleCancel}>
        <div className="modal-wrap">
          <div className="modal-header">

            <img src={model} className="model-header-img" alt="" />
            <div className="modal-header-text">
              微信扫码，开启专属咨询服务！

            </div>
          </div>
          <div className="model-content">
            <img src={qrcode} className="model-content-img" alt="" />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default App;
